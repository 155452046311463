import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import VueMask from "v-mask";
import DatetimePicker from "vuetify-datetime-picker";
import _ from "lodash";
import VueIziToast from "vue-izitoast";
import "izitoast/dist/css/iziToast.css";
import "./styles/main.scss";
import "./services/index.js";
import VueApexCharts from "vue-apexcharts";
import pt_br from "./locales/pt-br.json";
import HoneybadgerVue from "@honeybadger-io/vue";

moment.locale("PT-BR");
Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, "$_", { value: _ });

Vue.prototype.moment = moment;
Vue.use(VueIziToast);
Vue.use(VueMask);
Vue.use(DatetimePicker);

Vue.use(VueApexCharts);
window.Apex.chart = {
  locales: [pt_br.apexcharts],
  defaultLocale: "pt-br",
};

const isProdOrHml = ["production", "homolog"].includes(process.env.NODE_ENV);
if (isProdOrHml) {
  Vue.use(HoneybadgerVue, {
    apiKey: process.env.VUE_APP_HONEYBADGER_KEY,
    environment: process.env.NODE_ENV,
    revision: process.env.VUE_APP_VERSION,
  });
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(value).format("DD MM YYYY");
  }
});

Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(value).format("DD/MM/YYYY HH:mm");
  }
});

Vue.filter("formatOnlyTime", function (value) {
  if (value) {
    return moment(value).format("HH:mm");
  }
});

Vue.filter("fromNow", function (value) {
  if (value) {
    return moment(value).fromNow();
  }
});

document.documentElement.style.setProperty(
  "--vh",
  `${window.innerHeight / 100}px`
);

function setDocHeight() {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight / 100}px`
  );
}
addEventListener("resize", setDocHeight);
addEventListener("orientationchange", setDocHeight);
