import { companyService } from "@/services";
import i18n from "@/i18n";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    headersDataTable: [
      {
        text: i18n.t("company.datatable.id"),
        value: "id",
        align: "left",
        width: 80,
      },
      {
        text: i18n.t("company.datatable.name"),
        value: "name",
      },
      {
        text: i18n.t("company.datatable.company_group"),
        value: "company_group.name",
      },
      {
        text: i18n.t("company.datatable.status"),
        value: "active",
        align: "center",
        width: 125,
      },
      {
        text: i18n.t("company.datatable.actions"),
        value: "actions",
        align: "center",
        width: 125,
        sortable: false,
      },
    ],
    optionsDataTable: {},
    listCount: null,
    menuCount: null,
    menuList: [],
    menuLoading: false,
    list: [],
    loadingList: false,
    form: { id: null, company_group_id: null, name: null, active: true },
  },
  actions: {
    getList({ commit, state, rootGetters }) {
      if (!state.loadingList && state.optionsDataTable.page !== undefined) {
        commit("setLoadingList", true);

        let query = "";
        query += rootGetters["Filter/getQueryFieldsFiltered"];
        state.optionsDataTable.filter = query;

        companyService.getList(state.optionsDataTable).then((resp) => {
          commit("setList", resp);
          commit("setLoadingList", false);
        });
      }
    },
    async getListMenu({ commit }, search) {
      commit("setMenuLoading", true);
      const filter = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ["name"],
        sortDesc: [false],
        filter: "&q[active_eq]=true",
      };
      if (search) {
        filter.filter += `&q[name_cont]=${search}`;
      }

      await companyService.getList(filter).then((resp) => {
        if (!search) {
          commit("setMenuCount", resp.meta.total_count);
        }
        commit("setMenuList", resp.data);
        commit("setMenuLoading", false);
      });
    },
    getById({ commit }, id) {
      companyService.getById(id).then((resp) => {
        commit("setForm", resp);
      });
    },
    async save({ dispatch, commit, state }) {
      if (state.form.id) {
        let id = state.form.id;
        await companyService.patch(id, state.form).then((resp) => {
          commit("updateOnListIfPossible", resp);
          dispatch(
            "Toast/toastSuccess",
            {
              message: "Empresa atualizado com sucesso",
            },
            { root: true }
          );
        });
      } else {
        await companyService
          .post(state.form)
          .then((resp) => {
            commit("addOnList", resp);
            dispatch(
              "Toast/toastSuccess",
              {
                message: "Empresa criado com sucesso",
              },
              { root: true }
            );
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
    },
    resetForm({ commit }) {
      commit("resetForm");
    },
    setForm({ commit }, form) {
      commit("setForm", form);
    },
    toggleActive({ dispatch }, company) {
      if (company.active) {
        companyService.inactive(company.id).then(() => {
          dispatch("getList");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.inactive_successfully", {
                model: i18n.tc("company.label", 1),
              }),
            },
            { root: true }
          );
        });
      } else {
        companyService.patch(company.id, { active: true }).then(() => {
          dispatch("getList");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.active_successfully", {
                model: i18n.tc("company.label", 1),
              }),
            },
            { root: true }
          );
        });
      }
    },
  },
  mutations: {
    setList(state, resp) {
      state.list = resp.data;
      state.listCount = resp.meta.total_count;
    },
    setMenuList(state, resp) {
      state.menuList = resp;
    },
    setMenuCount(state, resp) {
      state.menuCount = resp;
    },
    setMenuLoading(state, bool) {
      state.menuLoading = bool;
    },
    setLoadingList(state, bool) {
      state.loadingList = bool;
    },
    updateOptionsDataTable(state, options) {
      state.optionsDataTable.page = options.page;
      state.optionsDataTable.itemsPerPage = options.itemsPerPage;
      state.optionsDataTable.sortBy = options.sortBy;
      state.optionsDataTable.sortDesc = options.sortDesc;
    },
    setForm(state, resp) {
      state.form = resp;
    },
    addOnList(state, resp) {
      state.list.unshift(resp);
    },
    resetForm(state) {
      state.form = {
        id: null,
        name: null,
        company_group: null,
        active: true,
      };
    },
    updateOnListIfPossible(state, resp) {
      let index = _.findIndex(state.list, { id: resp.id });
      if (index !== -1) {
        state.list[index].active = resp.active;
        state.list[index].id = resp.id;
        state.list[index].name = resp.name;
        state.list[index].company_group = resp.company_group;
      }
    },
  },
  getters: {
    getHeaderDataTable(state) {
      return state.headersDataTable;
    },
    getLoadingList(state) {
      return state.loadingList;
    },
    getListCount(state) {
      return state.listCount;
    },
    getListLoaded(state) {
      return state.list;
    },
    getForm(state) {
      return state.form;
    },
    getMenuList(state) {
      return state.menuList;
    },
    getMenuCount(state) {
      return state.menuCount;
    },
    getMenuLoading(state) {
      return state.menuLoading;
    },
  },
};
