import base from "@/services/modules/base.service.js";

export const eventService = {
  getList,
  index,
  trends,
  histogram,
  mostFrequentPops,
  post,
};

async function index(options) {
  var response = null;
  let url = `/events?page=${options.page}&per_page=${options.itemsPerPage}`;
  if (options.sortBy) {
    options.sortBy.map((s, i) => {
      url += `&order[]=${s} ${options.sortDesc[i] === true ? "desc" : "asc"}`;
    });
  }

  if (options.filter) {
    url += options.filter;
  }

  await base.fetch.get(url).then((resp) => {
    response = resp.data;
  });
  return response;
}

async function getList(options) {
  var response = null;
  let url = `/events?page=${options.page}&per_page=${options.itemsPerPage}`;
  if (options.sortBy) {
    options.sortBy.map((s, i) => {
      url += `&order[]=${s} ${options.sortDesc[i] === true ? "desc" : "asc"}`;
    });
  }

  if (options.filter) {
    url += options.filter;
  }

  await base.fetch.get(url).then((resp) => {
    response = resp.data;
  });
  return response;
}

async function trends(query) {
  const response = await base.fetch.get(`/events/trends?${query}`);
  return response.data;
}

async function histogram(query) {
  const response = await base.fetch.get(`/events/histogram?${query}`);
  return response.data;
}

async function mostFrequentPops(query) {
  const response = await base.fetch.get(`/events/most_frequent_pops?${query}`);
  return response.data;
}

async function post(params) {
  var response = null;
  await base.fetch.post("/events", params).then((resp) => {
    response = resp.data.data;
  });
  return response;
}
