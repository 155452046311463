import base from "@/services/modules/base.service.js";

export const userService = {
  getById,
  getList,
  getListAutocomplete,
  inactive,
  patch,
  post,
  createPassword,
  resetPassword,
  sendCode,
  resendCode,
};

async function getById(id) {
  var response = null;
  await base.fetch.get(`users/${id}`).then((resp) => {
    response = resp.data.data;
  });
  return response;
}

async function getListAutocomplete(search) {
  var response = null;
  if (search == null || search == "") {
    await this.getList({ page: 1, itemsPerPage: 10 }).then((resp) => {
      response = resp.data;
    });
  } else {
    await base.fetch
      .get(`/users?q[name_cont]=${search ? search : ""}`)
      .then((resp) => {
        response = resp.data.data;
      });
  }
  return response;
}

async function getList(options) {
  var response = null;
  let url = `/users?page=${options.page}&per_page=${options.itemsPerPage}`;
  if (options.sortBy) {
    options.sortBy.map((s, i) => {
      url += `&order[]=${s} ${options.sortDesc[i] === true ? "desc" : "asc"}`;
    });
  }
  if (options.filter) {
    url += options.filter;
  }
  await base.fetch.get(url).then((resp) => {
    response = resp.data;
  });
  return response;
}

async function inactive(id) {
  var response = null;
  await base.fetch.delete(`/users/${id}`).then((resp) => {
    response = resp;
  });
  return response;
}

async function patch(id, params) {
  var response = null;
  await base.fetch.patch(`/users/${id}`, params).then((resp) => {
    response = resp.data.data;
  });
  return response;
}

async function post(params) {
  var response = null;
  await base.fetch.post("/users", params).then((resp) => {
    response = resp.data.data;
  });
  return response;
}

async function createPassword(id, token, params) {
  var response = null;
  await base.fetch
    .patch(`/users/${id}`, params, {
      headers: {
        Authorization: token,
      },
    })
    .then((resp) => {
      response = resp.data.data;
    });
  return response;
}

async function resetPassword(params) {
  var response = null;
  await base.fetch.patch("/reset_password", params).then((resp) => {
    response = resp.data.data;
  });
  return response;
}

async function sendCode(params) {
  var response = null;
  await base.fetch
    .patch("/users/confirm_account_update", params)
    .then((resp) => {
      response = resp.data.data;
    });
  return response;
}

async function resendCode(params) {
  var response = null;
  await base.fetch.post("/resend_code", params).then((resp) => {
    response = resp;
  });
  return response;
}
