import pop from "../../../test/data/pop.json";
import packages from "../../../test/data/packages.json";
import events from "../../../test/data/events.json";

export default {
  namespaced: true,
  state: {
    ...pop,
    ...packages,
    ...events,
  },
  getters: {
    getAxesPackages(state) {
      return state.packages.filter((p) =>
        [
          "Measure::Vibration::X",
          "Measure::Vibration::Y",
          "Measure::Vibration::Z",
        ].includes(p.type)
      );
    },
  },
};
