const toastErrorDefaultConfig = {
  position: "topCenter",
  layout: 1,
  icon: "material-icons",
  iconText: "error",
  iconColor: "white",
  theme: "dark",
  messageColor: "white",
  backgroundColor: "#b70f0a",
  closeOnEscape: "true",
};

export default {
  namespaced: true,
  state: {
    type: null,
    message: null,
    title: null,
    config: null,
  },
  actions: {
    toastSuccess({ commit }, options) {
      commit("success", options);
    },
    toastError({ commit }, options) {
      commit("error", options);
    },
    toastInfo({ commit }, options) {
      commit("info", options);
    },
  },
  mutations: {
    success(state, options) {
      state.message = options.message;
      state.title = options.title ? options.title : "";
      if (options.config) {
        state.config = options.config;
      } else {
        state.config = {
          position: "topCenter",
          layout: 1,
          icon: "material-icons",
          iconText: "check_circle",
          iconColor: "white",
          messageColor: "white",
          backgroundColor: "#008665",
          closeOnClick: "true",
          theme: "dark",
        };
      }
      state.type = "success";
    },
    error(state, options) {
      state.message = options.message;
      state.title = options.title ? options.title : "";
      state.config = { ...toastErrorDefaultConfig, ...options.config };
      state.type = "error";
    },
    info(state, options) {
      state.message = options.message;
      state.title = options.title ? options.title : "";
      if (options.config) {
        state.config = options.config;
      } else {
        state.config = {
          position: "topCenter",
          layout: 1,
          icon: "material-icons",
          iconText: "info",
          iconColor: "white",
          messageColor: "white",
          backgroundColor: "#1565C0",
          closeOnEscape: "true",
          theme: "dark",
        };
      }
      state.type = "info";
    },
    set_type(state, type) {
      state.type = type;
    },
  },
  getters: {
    show_alert(state) {
      return state.type;
    },
  },
};
