import Vue from "vue";
import VueRouter from "vue-router";
import base from "@/services/modules/base.service.js";
import store from "@/store";
import i18n from "@/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/DefaultView.vue"),
    children: [
      {
        name: "Dashboard",
        path: "/",
        component: () => import("@/views/Dashboard/Dashboard.vue"),
        meta: {
          permissions: ["admin", "solvianist", "operational", "common"],
        },
      },
      {
        name: "Configuração do Perfil",
        path: "account",
        redirect: "account/edit",
        component: () => import("@/views/AccountSettings/NavigationDrawer.vue"),
        children: [
          {
            name: "Informações básicas",
            path: "edit",
            component: () => import("@/views/AccountSettings/Edit.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational", "common"],
            },
          },
          {
            name: "Alterar senha",
            path: "change_password",
            component: () =>
              import("@/views/AccountSettings/ChangePassword.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational", "common"],
            },
          },
          {
            name: "Aparência",
            path: "appearance",
            component: () => import("@/views/AccountSettings/Appearance.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational", "common"],
            },
          },
        ],
      },

      {
        name: "Administração",
        path: "settings",
        redirect: "/settings/import_new_operation",
        component: () =>
          import("@/views/RegisterSettings/NavigationDrawer.vue"),
        children: [
          {
            name: "Importar nova operação",
            path: "import_new_operation",
            component: () =>
              import("@/views/RegisterSettings/ImportNewOperation.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de grupos de empresas",
            path: "company_group",
            component: () =>
              import("@/views/RegisterSettings/CompanyGroup.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de Empresas",
            path: "company",
            component: () => import("@/views/RegisterSettings/Company.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de Sites",
            path: "site",
            component: () => import("@/views/RegisterSettings/Site.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de ambientes",
            path: "place",
            component: () => import("@/views/RegisterSettings/Place.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de equipamentos",
            path: "equipment",
            component: () => import("@/views/RegisterSettings/Equipment.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de modems",
            path: "modem",
            component: () => import("@/views/RegisterSettings/Modem.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de sensores",
            path: "sensor",
            component: () => import("@/views/RegisterSettings/Sensor.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de pontos de operação",
            path: "pop",
            component: () => import("@/views/RegisterSettings/Pop.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de tipos de eventos",
            path: "event_type",
            component: () => import("@/views/RegisterSettings/EventType.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de tags",
            path: "tag",
            component: () => import("@/views/RegisterSettings/Tags.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de template de sensor",
            path: "sensor_template",
            component: () =>
              import("@/views/RegisterSettings/SensorTemplate.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de regras de notificação",
            path: "rules",
            component: () => import("@/views/RegisterSettings/Rules.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
          {
            name: "Lista de usuários",
            path: "user",
            component: () => import("@/views/RegisterSettings/User.vue"),
            meta: {
              permissions: ["admin", "solvianist", "operational"],
            },
          },
        ],
      },
      {
        name: "Events",
        path: "events",
        component: () => import("@/views/Events/Events"),
        meta: {
          permissions: ["admin", "solvianist", "operational", "common"],
        },
      },
      {
        name: "Detalhe do pop",
        path: "pop/:id",
        component: () => import("@/views/Pops/Details.vue"),
        meta: {
          permissions: ["admin", "solvianist", "operational", "common"],
        },
      },
      {
        name: "Sites",
        path: "sites",
        component: () => import("@/views/Sites/Sites"),
        meta: {
          permissions: ["admin", "solvianist", "operational", "common"],
        },
      },
      {
        name: "Pops",
        path: "pops",
        component: () => import("@/views/Pops/Pops"),
        meta: {
          permissions: ["admin", "solvianist", "operational", "common"],
        },
      },
    ],
  },
  {
    path: "*",
    name: "Error",
    component: () => import("@/views/Erros/404.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/signin",
    name: "Login",
    component: () => import("@/views/Login/Login.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/reset_password",
    name: "Alteração de senha",
    component: () => import("@/views/Login/ResetPassword.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/change_password",
    name: "Nova senha",
    component: () => import("@/views/Login/CreatePassword.vue"),
    meta: {
      public: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  const authRequired = !to.meta?.public;
  const role = localStorage.getItem("mf-userRole");
  document.title = to.name ? "MachineFlow - " + to.name : "MachineFlow";

  if (authRequired && !base.isAuthenticate()) {
    store.dispatch(
      "Toast/toastInfo",
      {
        message: i18n.t("default.msg.token_expired"),
      },
      { root: true }
    );
    return next("/signin");
  }
  if (!authRequired) {
    next();
  } else if (to.meta?.permissions?.includes(role)) {
    next();
  } else {
    if (from.name === null) {
      next("/");
    }
    store.dispatch(
      "Toast/toastError",
      {
        message: i18n.t("generic_msgs.permission_denied"),
      },
      { root: true }
    );
  }
});

export default router;
