class EventHelper {
  static eventTypes = {
    active_for_a_long_time: {
      color: "#ff9900",
      ranged: false,
    },
    threshold_overflow_warning: {
      color: "#ff9900",
      ranged: true,
    },
  };

  static typeColors = {
    disconnection: "#606060",
    abnormally_high_consumption: "#0098c6",
    low_level: "#994499",
    threshold_overflow_warning: "#3366cc",
    threshold_overflow_critical: "#ff9900",
    threshold_overflow_equipment: "#dc3912",
    vibration_class_warning: "#22aa99",
    vibration_class_critical: "#316395",
    anomaly_warning: "#66aa00",
    anomaly_critical: "#109618",
    daily_anomaly_warning: "#dd4477",
    daily_anomaly_critical: "#990099",
    active_for_a_long_time: "#6633cc",
    switch_on: "#b82e2e",
    switch_off: "#e67300",
    training: "#3366cc",
    maintenance: "#3366cc",
    low_battery: "#3366cc",
    signaling: "#3366cc",
    inactivation: "#3366cc",
  };

  static typeIds = {
    9: "active_for_a_long_time",
    7: "threshold_overflow_warning",
  };

  static colorForType(type) {
    return this.typeColors[type];
  }

  static eventFromId(id) {
    return this.eventTypes[this.typeIds[id]];
  }
}

export default EventHelper;
