import { sensorService } from "@/services";
import i18n from "@/i18n";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    types: [
      {
        name: "Nível",
        value: "Sensor::Level",
      },
      {
        name: "Contador genérico",
        value: "Sensor::Counter",
      },
      {
        name: "Contador de pulso",
        value: "Sensor::PulseCounter",
      },
      {
        name: "Termômetro",
        value: "Sensor::Thermometer",
      },
      {
        name: "Termômetro WS2 (Ayga)",
        value: "Sensor::ThermometerWS2",
      },
      {
        name: "Vibração",
        value: "Sensor::Vibration",
      },
      {
        name: "Interruptor",
        value: "Sensor::Switch",
      },
      {
        name: "Analógico",
        value: "Sensor::Analog",
      },
      {
        name: "Sinal",
        value: "Sensor::Signal",
      },
      {
        name: "Qualidade de ambiente",
        value: "Sensor::Environment",
      },
      {
        name: "Ar-condicionado",
        value: "Sensor::AirConditioner",
      },
      {
        name: "Energia",
        value: "Sensor::Energy",
      },
    ],
    packagesTypes: [
      {
        value: "Package",
        name: "Pacotes analógicos",
      },
      {
        value: "Measure::Temperature",
        name: "Temperatura",
      },
      {
        value: "Measure::Humidity",
        name: "Umidade",
      },
      {
        value: "Measure::Environment",
        name: "Qualidade do ambiente",
      },
      {
        value: "Measure::Level",
        name: "Nível",
      },
      {
        value: "Measure::SwitchStatus",
        name: "Interruptor",
      },
      {
        value: "Measure::Counter",
        name: "Contagem",
      },
      {
        value: "Measure::Vibration::X",
        name: "Vibração sobre o eixo X",
      },
      {
        value: "Measure::Vibration::Y",
        name: "Vibração sobre o eixo Y",
      },
      {
        value: "Measure::Vibration::Z",
        name: "Vibração sobre o eixo Z",
      },
      {
        value: "Measure::Signal",
        name: "Sinal",
      },
      {
        value: "Measure::AirConditioner",
        name: "Ar-condicionado",
      },
      {
        value: "Measure::Energy",
        name: "Energia",
      },
    ],
    headersDataTable: [
      {
        text: i18n.t("sensor.datatable.id"),
        value: "id",
        align: "left",
        width: 80,
      },
      {
        text: i18n.t("sensor.datatable.serial"),
        value: "serial",
      },
      {
        text: i18n.t("sensor.datatable.type"),
        value: "type",
      },
      {
        text: i18n.t("sensor.datatable.modem"),
        value: "modem.mac",
      },
      {
        text: i18n.t("sensor.datatable.company"),
        value: "company.name",
      },
      {
        text: i18n.t("sensor.datatable.status"),
        value: "active",
        align: "center",
        width: 125,
      },
      {
        text: i18n.t("sensor.datatable.actions"),
        value: "actions",
        align: "center",
        width: 125,
        sortable: false,
      },
    ],
    optionsDataTable: {},
    listCount: null,
    list: [],
    loadingList: false,
    form: { id: null, name: null, type: null, active: true },
  },
  actions: {
    getList({ commit, state, rootGetters }) {
      if (!state.loadingList && state.optionsDataTable.page !== undefined) {
        commit("setLoadingList", true);

        const company = rootGetters["Filter/getCompany"];
        let query = "";
        if (company.id) {
          query += `&q[company_id_eq]=${company.id}`;
        }
        query += rootGetters["Filter/getQueryFieldsFiltered"];
        state.optionsDataTable.filter = query;

        sensorService.getList(state.optionsDataTable).then((resp) => {
          commit("setList", resp);
          commit("setLoadingList", false);
        });
      }
    },
    getById({ commit }, id) {
      sensorService.getById(id).then((resp) => {
        commit("setForm", resp);
      });
    },
    async save({ dispatch, commit, state }) {
      if (state.form.id) {
        //update
        let id = state.form.id;
        await sensorService.patch(id, state.form).then((resp) => {
          commit("updateOnListIfPossible", resp);
          dispatch(
            "Toast/toastSuccess",
            {
              message: "Sensor atualizado com sucesso",
            },
            { root: true }
          );
        });
      } else {
        await sensorService
          .post(state.form)
          .then((resp) => {
            commit("addOnList", resp);
            dispatch(
              "Toast/toastSuccess",
              {
                message: "Sensor criado com sucesso",
              },
              { root: true }
            );
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
    },
    resetForm({ commit }) {
      commit("resetForm");
    },
    setForm({ commit }, form) {
      commit("setForm", form);
    },
    toggleActive({ dispatch }, sensor) {
      if (sensor.active) {
        sensorService.inactive(sensor.id).then(() => {
          dispatch("getList");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.inactive_successfully", {
                model: i18n.tc("sensor.label", 1),
              }),
            },
            { root: true }
          );
        });
      } else {
        sensorService.patch(sensor.id, { active: true }).then(() => {
          dispatch("getList");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.active_successfully", {
                model: i18n.tc("sensor.label", 1),
              }),
            },
            { root: true }
          );
        });
      }
    },
  },
  mutations: {
    setList(state, resp) {
      state.list = resp.data;
      state.listCount = resp.meta.total_count;
    },
    setLoadingList(state, bool) {
      state.loadingList = bool;
    },
    updateOptionsDataTable(state, options) {
      state.optionsDataTable.page = options.page;
      state.optionsDataTable.itemsPerPage = options.itemsPerPage;
      state.optionsDataTable.sortBy = options.sortBy;
      state.optionsDataTable.sortDesc = options.sortDesc;
    },
    setForm(state, resp) {
      state.form = resp;
    },
    addOnList(state, resp) {
      state.list.unshift(resp);
    },
    resetForm(state) {
      state.form = {
        id: null,
        type: null,
        modem_id: null,
        sensor_template_id: null,
        active: true,
        config: {
          flow_factor: null,
        },
      };
    },
    updateOnListIfPossible(state, resp) {
      let index = _.findIndex(state.list, { id: resp.id });
      if (index !== -1) {
        state.list[index].active = resp.active;
        state.list[index].id = resp.id;
        state.list[index].serial = resp.serial;
        state.list[index].type = resp.type;
        state.list[index].modem = resp.modem;
        state.list[index].company = resp.company;
      }
    },
  },
  getters: {
    getHeaderDataTable(state) {
      return state.headersDataTable;
    },
    getLoadingList(state) {
      return state.loadingList;
    },
    getListCount(state) {
      return state.listCount;
    },
    getListLoaded(state) {
      return state.list;
    },
    getPackagesTypes(state) {
      return state.packagesTypes;
    },
    getSensorTypes(state) {
      return state.types;
    },
    getForm(state) {
      return state.form;
    },
  },
};
