import { modemService } from "@/services";
import i18n from "@/i18n";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    headersDataTable: [
      {
        text: i18n.t("modem.datatable.id"),
        value: "id",
        align: "left",
        width: 80,
      },
      {
        text: i18n.t("modem.datatable.mac"),
        value: "mac",
      },
      {
        text: i18n.t("modem.datatable.chip"),
        value: "chip",
      },
      {
        text: i18n.t("modem.datatable.company"),
        value: "company.name",
      },
      {
        text: i18n.t("modem.datatable.status"),
        value: "active",
        align: "center",
        width: 125,
      },
      {
        text: i18n.t("modem.datatable.actions"),
        value: "actions",
        align: "center",
        width: 125,
        sortable: false,
      },
    ],
    optionsDataTable: {},
    listCount: null,
    list: [],
    loadingList: false,
    form: {
      id: null,
      mac: null,
      chip: null,
      company_id: null,
      active: true,
    },
  },
  actions: {
    getList({ commit, state, rootGetters }) {
      if (!state.loadingList && state.optionsDataTable.page !== undefined) {
        commit("setLoadingList", true);
        const company = rootGetters["Filter/getCompany"];
        let query = "";
        if (company.id) {
          query += `&q[company_id_eq]=${company.id}`;
        }
        query += rootGetters["Filter/getQueryFieldsFiltered"];
        state.optionsDataTable.filter = query;
        modemService.getList(state.optionsDataTable).then((resp) => {
          commit("setList", resp);
          commit("setLoadingList", false);
        });
      }
    },
    getById({ commit }, id) {
      modemService.getById(id).then((resp) => {
        commit("setForm", resp);
      });
    },
    async save({ dispatch, commit, state }) {
      if (state.form.id) {
        //update
        let id = state.form.id;
        await modemService.patch(id, state.form).then((resp) => {
          commit("updateOnListIfPossible", resp);
          dispatch(
            "Toast/toastSuccess",
            {
              message: "Modem atualizado com sucesso",
            },
            { root: true }
          );
        });
      } else {
        await modemService
          .post(state.form)
          .then((resp) => {
            commit("addOnList", resp);
            dispatch(
              "Toast/toastSuccess",
              {
                message: "Modem criado com sucesso",
              },
              { root: true }
            );
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
    },
    resetForm({ commit }) {
      commit("resetForm");
    },
    setForm({ commit }, form) {
      commit("setForm", form);
    },
    toggleActive({ dispatch }, modem) {
      if (modem.active) {
        modemService.inactive(modem.id).then(() => {
          dispatch("getList");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.inactive_successfully", {
                model: i18n.tc("modem.label", 1),
              }),
            },
            { root: true }
          );
        });
      } else {
        modemService.patch(modem.id, { active: true }).then(() => {
          dispatch("getList");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.active_successfully", {
                model: i18n.tc("modem.label", 1),
              }),
            },
            { root: true }
          );
        });
      }
    },
  },
  mutations: {
    setList(state, resp) {
      state.list = resp.data;
      state.listCount = resp.meta.total_count;
    },
    setLoadingList(state, bool) {
      state.loadingList = bool;
    },
    updateOptionsDataTable(state, options) {
      state.optionsDataTable.page = options.page;
      state.optionsDataTable.itemsPerPage = options.itemsPerPage;
      state.optionsDataTable.sortBy = options.sortBy;
      state.optionsDataTable.sortDesc = options.sortDesc;
    },
    setForm(state, resp) {
      state.form = resp;
    },
    addOnList(state, resp) {
      state.list.unshift(resp);
    },
    resetForm(state) {
      state.form = {
        id: null,
        name: null,
        company_group: null,
        active: true,
      };
    },
    updateOnListIfPossible(state, resp) {
      let index = _.findIndex(state.list, { id: resp.id });
      if (index !== -1) {
        state.list[index].active = resp.active;
        state.list[index].id = resp.id;
        state.list[index].mac = resp.mac;
        state.list[index].chip = resp.chip;
        state.list[index].company = resp.company;
      }
    },
  },
  getters: {
    getHeaderDataTable(state) {
      return state.headersDataTable;
    },
    getLoadingList(state) {
      return state.loadingList;
    },
    getListCount(state) {
      return state.listCount;
    },
    getListLoaded(state) {
      return state.list;
    },
    getForm(state) {
      return state.form;
    },
  },
};
