import _ from "lodash";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    showSearch: false,
    pathComponent: null,
    rootFilterView: false,
    filteredFields: [],
    initialize: [],
    company: {
      id: null,
      name: null,
    },
    site: {
      id: null,
      name: null,
    },
    place: {
      id: null,
      name: null,
    },
    equipment: {
      id: null,
      name: null,
    },
    packagesTimeRange: [],
  },
  actions: {
    setCompany({ commit, dispatch }, val) {
      commit("company", val);
      commit("site", { id: null, name: null });
      commit("place", { id: null, name: null });
      commit("equipment", { id: null, name: null });

      dispatch("Site/getListMenu", null, { root: true });
      dispatch("Place/getListMenu", null, { root: true });
      dispatch("Equipment/getListMenu", null, { root: true });
      dispatch("Pop/getListMenu", null, { root: true });
    },
    setSite({ commit, dispatch }, val) {
      commit("site", val);
      commit("place", { id: null, name: null });
      commit("equipment", { id: null, name: null });

      dispatch("Place/getListMenu", null, { root: true });
      dispatch("Equipment/getListMenu", null, { root: true });
      dispatch("Pop/getListMenu", null, { root: true });
    },
    setPlace({ commit, dispatch }, val) {
      commit("place", val);
      commit("equipment", { id: null, name: null });

      dispatch("Equipment/getListMenu", null, { root: true });
      dispatch("Pop/getListMenu", null, { root: true });
    },
    setEquipment({ commit, dispatch }, val) {
      commit("equipment", val);
      dispatch("Pop/getListMenu", null, { root: true });
    },
    setShowSearch({ commit }, config) {
      commit("showSearch", config.show);
      commit("pathComponent", config.pathComponent);
      commit("rootFilterView", config.rootFilterView);

      if (config.reset_filter) {
        commit("resetFilteredFields");
      }

      let filteredFields = [];
      if (config.initialize) {
        filteredFields = config.initialize;
        commit("setInitialize", filteredFields);
      } else {
        commit("setInitialize", []);
      }
      commit("setFilteredFields", filteredFields);
    },
    setFieldOnFilterdFields({ commit }, field) {
      commit("setFilteredFields", field);
    },
    resetFilter({ commit }) {
      commit("resetFilteredFields");
    },
  },
  mutations: {
    company(state, val) {
      state.company = val;
    },
    site(state, val) {
      state.site = val;
    },
    place(state, val) {
      state.place = val;
    },
    equipment(state, val) {
      state.equipment = val;
    },
    showSearch(state, bool) {
      state.showSearch = bool;
    },
    pathComponent(state, path) {
      state.pathComponent = path;
    },
    rootFilterView(state, bool) {
      state.rootFilterView = bool;
    },
    setFilteredFields(state, val) {
      let fields = {};
      if (val) {
        fields = { ...state.filteredFields };
        for (let a in val) {
          fields[a] = val[a];
        }
      }
      state.filteredFields = fields;
    },
    resetFilteredFields(state) {
      state.filteredFields = state.initialize;
    },
    setPackagesTimeRange(state, range) {
      state.packagesTimeRange = range.map((date, index) => {
        date = moment(date);
        if (index === 0) {
          date = date.startOf("day");
        } else {
          date = date.endOf("day");
        }
        return date.toISOString();
      });
    },
    setInitialize(state, val) {
      state.initialize = val;
    },
  },
  getters: {
    getCompany(state) {
      return state.company;
    },
    getSite(state) {
      return state.site;
    },
    getPlace(state) {
      return state.place;
    },
    getEquipment(state) {
      return state.equipment;
    },
    getShowSearch(state) {
      return state.showSearch;
    },
    getPathComponent(state) {
      return state.pathComponent;
    },
    getFilteredFields(state) {
      return state.filteredFields;
    },
    getQueryFieldsFiltered(state) {
      let query = "";
      if (!_.isEmpty(state.filteredFields)) {
        query = Object.keys(state.filteredFields)
          .map((e) => {
            let q = null;
            if (state.filteredFields[e]) {
              q = `&q[${e}]=${state.filteredFields[e]}`;
            }
            return q;
          })
          .join("");
      }
      return query;
    },
    getPackagesTimeRange(state) {
      return state.packagesTimeRange;
    },
    getInitialize(state) {
      return state.initialize;
    },
  },
};
