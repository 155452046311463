import axios from "axios";
import store from "@/store";
import i18n from "@/i18n";
import { userService } from "@/services";

const fetch = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});
const ERROR_CODES = {
  PASSWORD_EXPIRED: 1002,
  NOT_FOUND: 404,
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422,
};

fetch.interceptors.request.use(function (config) {
  const access_token = localStorage.getItem("mf-auth");
  if (access_token) {
    config.headers["Authorization"] = access_token;
  }
  config.headers["Accept"] = "*/*";
  return config;
});

fetch.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let error_message = "";

    // PASSWORD_EXPIRED
    if (error.response.data.code === ERROR_CODES.PASSWORD_EXPIRED) {
      clearAccountStorage();
      window.location.replace("/reset_password");
      return;
    }

    // UNPROCESSABLE ENTITY
    if (error.response.status === ERROR_CODES.UNPROCESSABLE_ENTITY) {
      let error_message = "";
      error_message =
        error.response.data != " "
          ? error_handler(error.response)
          : error.response.statusText;

      store.dispatch(
        "Toast/toastError",
        {
          message: error_message,
          config: {
            backgroundColor: "#565c70",
            progressBarColor: "#ff3f43",
            position: "topCenter",
            layout: 1,
            icon: "material-icons",
            iconText: "error",
            iconColor: "white",
            messageColor: "white",
            theme: "dark",
          },
        },
        { root: true }
      );
    }

    // UNAUTHORIZED
    if (error.response.status === ERROR_CODES.UNAUTHORIZED) {
      if (new RegExp("reset_password").test(location.href)) {
        store.dispatch("Account/authenticationFailed");
        return;
      } else if (!new RegExp("signin").test(location.href)) {
        logout();
        return;
      }
    }

    // NOT_FOUND (RESET PASSWORD) CODE INVALID
    if (error.response.status === ERROR_CODES.NOT_FOUND) {
      if (new RegExp("reset_password").test(location.href)) {
        store.dispatch(
          "Toast/toastError",
          {
            message: i18n.t("password_reset.code_invalid"),
            config: {
              backgroundColor: "#565c70",
              progressBarColor: "#ff3f43",
              position: "topCenter",
              layout: 1,
              icon: "material-icons",
              iconText: "error",
              iconColor: "white",
              messageColor: "white",
              theme: "dark",
            },
          },
          { root: true }
        );
      }
    }
    return Promise.reject(error_message);
  }
);

export default {
  fetch,
  login,
  logout,
  forgotPassword,
  isAuthenticate,
};

async function login(email, password) {
  await store.dispatch("Account/authenticating");

  try {
    const userToken = await fetch.post("/user_token", {
      auth: { email: email, password: password },
    });
    localStorage.setItem("mf-auth", userToken.data.data.jwt);
    localStorage.setItem("mf-userId", userToken.data.data.id);

    const loggedInUser = await userService.getById(userToken.data.data.id);

    localStorage.setItem("mf-userRole", loggedInUser.role ?? "common");
    localStorage.setItem(
      "mf-lowestSeverity",
      loggedInUser.config?.appearance?.lowest_severity_of_interest ?? 1
    );

    await store.dispatch("Account/authenticationSuccessful", loggedInUser);
  } catch (err) {
    await store.dispatch("Account/authenticationFailed");
  }
}

function clearAccountStorage() {
  localStorage.removeItem("mf-auth");
  localStorage.removeItem("mf-userId");
  localStorage.removeItem("mf-userRole");
  localStorage.removeItem("mf-lowestSeverity");
}

function logout() {
  clearAccountStorage();
  window.location.replace("/signin");
}

async function forgotPassword(email) {
  store.dispatch("Account/authenticating");
  let resp = "";
  await fetch
    .post("/v2/recover_password", { email: email })
    .then(() => {
      store.dispatch("Account/authenticationSuccessful");
      store.dispatch(
        "Toast/toastSuccess",
        {
          message: i18n.t("login.msg.instructions_sent"),
        },
        { root: true }
      );
      resp = "VALID";
    })
    .catch(() => {
      store.dispatch("Account/authenticationFailed");
      store.dispatch(
        "Toast/toastError",
        {
          message: i18n.t("login.msg.email_not_found"),
          config: {
            backgroundColor: "#565c70",
            progressBarColor: "#ff3f43",
          },
        },
        { root: true }
      );
      resp = "INVALID";
    });

  return resp;
}

function isAuthenticate() {
  return localStorage.getItem("mf-auth") ? true : false;
}

function error_handler(handler) {
  let error_labeled = "";
  console.error("ERRO -> ", handler);
  for (let k in handler.data) {
    let errors = "";
    for (let i in handler.data[k]) {
      if (handler.data[k].length > 1) {
        errors = handler.data[k][i] + "<br>" + errors;
      } else {
        errors = handler.data[k][i];
      }
    }

    let keyTranslated = i18n.t(`errors.key.${k}`);
    error_labeled = `<b>${keyTranslated}:</b> ${errors}<br> ${error_labeled}`;
  }

  return error_labeled;
}
