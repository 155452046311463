import { sensorTemplateService } from "@/services";
import i18n from "@/i18n";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    headersDataTable: [
      {
        text: i18n.t("sensor_template.datatable.id"),
        value: "id",
        align: "left",
        width: 80,
      },
      {
        text: i18n.t("sensor_template.datatable.name"),
        value: "name",
      },
      {
        text: i18n.t("sensor_template.datatable.description"),
        value: "description",
      },
      {
        text: i18n.t("sensor_template.datatable.value_min"),
        value: "min_value",
      },
      {
        text: i18n.t("sensor_template.datatable.value_max"),
        value: "max_value",
      },
      {
        text: i18n.t("sensor_template.datatable.unit"),
        value: "unit",
      },
      {
        text: i18n.t("sensor_template.datatable.status"),
        value: "active",
        width: 130,
      },
      {
        text: i18n.t("sensor_template.datatable.actions"),
        value: "actions",
        align: "center",
        width: 125,
        sortable: false,
      },
    ],
    optionsDataTable: {},
    listCount: null,
    list: [],
    loadingList: false,
    form: {
      id: null,
      name: null,
      description: null,
      min_value: null,
      max_value: null,
      sensor_min: null,
      sensor_max: null,
      pop_type: null,
      sensor_type: null,
      unit: null,
      active: true,
    },
  },
  actions: {
    getList({ commit, state, rootGetters }) {
      if (!state.loadingList && state.optionsDataTable.page !== undefined) {
        commit("setLoadingList", true);

        let query = "";
        query += rootGetters["Filter/getQueryFieldsFiltered"];
        state.optionsDataTable.filter = query;

        sensorTemplateService.getList(state.optionsDataTable).then((resp) => {
          commit("setList", resp);
          commit("setLoadingList", false);
        });
      }
    },
    getById({ commit }, id) {
      sensorTemplateService.getById(id).then((resp) => {
        commit("resetForm");
        commit("setForm", resp);
      });
    },
    async save({ dispatch, commit, state }) {
      if (state.form.id) {
        //update
        let id = state.form.id;
        await sensorTemplateService.patch(id, state.form).then((resp) => {
          commit("updateOnListIfPossible", resp);
          dispatch(
            "Toast/toastSuccess",
            {
              message: "Template de sensor atualizado com sucesso",
            },
            { root: true }
          );
        });
      } else {
        await sensorTemplateService
          .post(state.form)
          .then((resp) => {
            commit("addOnList", resp);
            dispatch(
              "Toast/toastSuccess",
              {
                message: "Template de sensor criado com sucesso",
              },
              { root: true }
            );
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
    },
    resetForm({ commit }) {
      commit("resetForm");
    },
    setForm({ commit }, form) {
      commit("setForm", form);
    },
    toggleActive({ dispatch }, sensorTemplate) {
      if (sensorTemplate.active) {
        sensorTemplateService.inactive(sensorTemplate.id).then(() => {
          dispatch("getList");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.inactive_successfully", {
                model: i18n.tc("sensor_template.label", 1),
              }),
            },
            { root: true }
          );
        });
      } else {
        sensorTemplateService
          .patch(sensorTemplate.id, { active: true })
          .then(() => {
            dispatch("getList");
            dispatch(
              "Toast/toastSuccess",
              {
                message: i18n.t("default.msg.active_successfully", {
                  model: i18n.tc("sensor_template.label", 1),
                }),
              },
              { root: true }
            );
          });
      }
    },
  },
  mutations: {
    setList(state, resp) {
      state.list = resp.data;
      state.listCount = resp.meta.total_count;
    },
    setLoadingList(state, bool) {
      state.loadingList = bool;
    },
    updateOptionsDataTable(state, options) {
      state.optionsDataTable.page = options.page;
      state.optionsDataTable.itemsPerPage = options.itemsPerPage;
      state.optionsDataTable.sortBy = options.sortBy;
      state.optionsDataTable.sortDesc = options.sortDesc;
    },
    resetForm(state) {
      state.form = {
        id: null,
        name: null,
        description: null,
        min_value: null,
        max_value: null,
        sensor_min: null,
        sensor_max: null,
        pop_type: null,
        pop_config: {
          wait_graciously: null,
          long_time: null,
          visible_packages: null,
          offset: null,
          battery_event_rules: {
            low_battery_level: null,
          },
        },
        sensor_config: {
          flow_factor: null,
          digital: null,
          three_floaters: null,
        },
        sensor_type: null,
        unit: null,
        active: true,
      };
    },
    setForm(state, resp) {
      state.form = resp;
    },
    addOnList(state, resp) {
      state.list.unshift(resp);
    },
    updateOnListIfPossible(state, resp) {
      let index = _.findIndex(state.list, { id: resp.id });
      if (index !== -1) {
        state.list[index].active = resp.active;
        state.list[index].id = resp.id;
        state.list[index].name = resp.name;
        state.list[index].description = resp.description;
        state.list[index].min_value = resp.min_value;
        state.list[index].max_value = resp.max_value;
        state.list[index].unit = resp.unit;
      }
    },
  },
  getters: {
    getHeaderDataTable(state) {
      return state.headersDataTable;
    },
    getLoadingList(state) {
      return state.loadingList;
    },
    getListCount(state) {
      return state.listCount;
    },
    getListLoaded(state) {
      return state.list;
    },
    getForm(state) {
      return state.form;
    },
  },
};
