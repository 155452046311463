import base from "@/services/modules/base.service.js";

export const eventTypeService = {
  getById,
  getList,
  getListAutocomplete,
  inactive,
  patch,
  post,
};

async function getList(options) {
  var response = null;
  let url = `/event_types?page=${options.page}&per_page=${options.itemsPerPage}`;
  if (options.sortBy) {
    options.sortBy.map((s, i) => {
      url += `&order[]=${s} ${options.sortDesc[i] === true ? "desc" : "asc"}`;
    });
  }
  if (options.filter) {
    url += options.filter;
  }
  await base.fetch.get(url).then((resp) => {
    response = resp.data;
  });
  return response;
}

async function getById(id) {
  var response = null;
  await base.fetch.get(`/event_types/${id}`).then((resp) => {
    response = resp.data.data;
  });
  return response;
}

async function getListAutocomplete(search) {
  var response = null;
  if (search == null || search == "") {
    await this.getList({ page: 1, itemsPerPage: 10 }).then((resp) => {
      response = resp.data;
    });
  } else {
    await base.fetch
      .get(`/event_types?q[name_cont]=${search ? search : ""}`)
      .then((resp) => {
        response = resp.data.data;
      });
  }
  return response;
}

async function inactive(id) {
  var response = null;
  await base.fetch.delete(`/event_types/${id}`).then((resp) => {
    response = resp;
  });
  return response;
}

async function patch(id, params) {
  var response = null;
  await base.fetch.patch(`/event_types/${id}`, params).then((resp) => {
    response = resp.data.data;
  });
  return response;
}

async function post(params) {
  var response = null;
  await base.fetch.post("/event_types", params).then((resp) => {
    response = resp.data.data;
  });
  return response;
}
