import { placeService } from "@/services";
import i18n from "@/i18n";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    headersDataTable: [
      {
        text: i18n.t("place.datatable.id"),
        value: "id",
        align: "left",
        width: 80,
      },
      {
        text: i18n.t("place.datatable.name"),
        value: "name",
      },
      {
        text: i18n.t("place.datatable.site"),
        value: "site.name",
      },
      {
        text: i18n.t("place.datatable.company"),
        value: "company.name",
      },
      {
        text: i18n.t("place.datatable.status"),
        value: "active",
        align: "center",
        width: 125,
      },
      {
        text: i18n.t("place.datatable.actions"),
        value: "actions",
        align: "center",
        width: 125,
        sortable: false,
      },
    ],
    optionsDataTable: {},
    menuCount: null,
    menuList: [],
    menuLoading: false,
    listCount: null,
    list: [],
    loadingList: false,
    form: { id: null, name: null, site_id: null, active: true },
  },
  actions: {
    getList({ commit, state, rootGetters }) {
      if (!state.loadingList && state.optionsDataTable.page !== undefined) {
        commit("setLoadingList", true);
        let array = [];
        ["Company", "Site"].forEach((element) => {
          array[element] = rootGetters[`Filter/get${element}`];
        });

        let query = "";
        for (let i in array) {
          if (array[i].id) {
            query += `&q[${i.toLowerCase()}_id_eq]=${array[i].id}`;
          }
        }
        query += rootGetters["Filter/getQueryFieldsFiltered"];
        state.optionsDataTable.filter = query;
        placeService.getList(state.optionsDataTable).then((resp) => {
          commit("setList", resp);
          commit("setLoadingList", false);
        });
      }
    },
    async getListMenu({ commit, rootGetters }, search) {
      commit("setMenuLoading", true);

      let array = [];
      ["Company", "Site"].forEach((element) => {
        array[element] = rootGetters[`Filter/get${element}`];
      });

      let query = "&q[active_eq]=true";
      for (let i in array) {
        if (array[i].id) {
          query += `&q[${i.toLowerCase()}_id_eq]=${array[i].id}`;
        }
      }

      if (search) {
        query += `&q[name_cont]=${search}`;
      }

      const filter = {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["name"],
        sortDesc: [false],
        filter: query,
      };

      await placeService.getList(filter).then((resp) => {
        if (!search) {
          commit("setMenuCount", resp.meta.total_count);
        }
        commit("setMenuList", resp.data);
        commit("setMenuLoading", false);
      });
    },
    getById({ commit }, id) {
      placeService.getById(id).then((resp) => {
        commit("setForm", resp);
      });
    },
    async save({ dispatch, commit, state }) {
      if (state.form.id) {
        //update
        let id = state.form.id;
        await placeService.patch(id, state.form).then((resp) => {
          commit("updateOnListIfPossible", resp);
          dispatch(
            "Toast/toastSuccess",
            {
              message: "Ambiente atualizado com sucesso",
            },
            { root: true }
          );
        });
      } else {
        await placeService
          .post(state.form)
          .then((resp) => {
            commit("addOnList", resp);
            dispatch(
              "Toast/toastSuccess",
              {
                message: "Ambiente criado com sucesso",
              },
              { root: true }
            );
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
    },
    resetForm({ commit }) {
      commit("resetForm");
    },
    setForm({ commit }, form) {
      commit("setForm", form);
    },
    toggleActive({ dispatch }, place) {
      if (place.active) {
        placeService.inactive(place.id).then(() => {
          dispatch("getList");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.inactive_successfully", {
                model: i18n.tc("place.label", 1),
              }),
            },
            { root: true }
          );
        });
      } else {
        placeService.patch(place.id, { active: true }).then(() => {
          dispatch("getList");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.active_successfully", {
                model: i18n.tc("place.label", 1),
              }),
            },
            { root: true }
          );
        });
      }
    },
  },
  mutations: {
    setList(state, resp) {
      state.list = resp.data;
      state.listCount = resp.meta.total_count;
    },
    setMenuList(state, resp) {
      state.menuList = resp;
    },
    setMenuCount(state, resp) {
      state.menuCount = resp;
    },
    setMenuLoading(state, bool) {
      state.menuLoading = bool;
    },
    setLoadingList(state, bool) {
      state.loadingList = bool;
    },
    updateOptionsDataTable(state, options) {
      state.optionsDataTable.page = options.page;
      state.optionsDataTable.itemsPerPage = options.itemsPerPage;
      state.optionsDataTable.sortBy = options.sortBy;
      state.optionsDataTable.sortDesc = options.sortDesc;
    },
    setForm(state, resp) {
      state.form = resp;
    },
    addOnList(state, resp) {
      state.list.unshift(resp);
    },
    resetForm(state) {
      state.form = {
        id: null,
        name: null,
        site: null,
        active: true,
      };
    },
    updateOnListIfPossible(state, resp) {
      let index = _.findIndex(state.list, { id: resp.id });
      if (index !== -1) {
        state.list[index].active = resp.active;
        state.list[index].id = resp.id;
        state.list[index].name = resp.name;
        state.list[index].site = resp.site;
        state.list[index].company = resp.company;
      }
    },
  },
  getters: {
    getHeaderDataTable(state) {
      return state.headersDataTable;
    },
    getLoadingList(state) {
      return state.loadingList;
    },
    getListCount(state) {
      return state.listCount;
    },
    getListLoaded(state) {
      return state.list;
    },
    getForm(state) {
      return state.form;
    },
    getMenuList(state) {
      return state.menuList;
    },
    getMenuCount(state) {
      return state.menuCount;
    },
    getMenuLoading(state) {
      return state.menuLoading;
    },
  },
};
