<template>
  <v-app>
    <v-main>
      <router-view class="d-block col-12 pa-0"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters("Toast", ["show_alert"]),
    ...mapState("Toast", ["message", "title", "config", "type"]),
  },
  watch: {
    show_alert(newValue) {
      if (newValue == "error") {
        this.$toast.error(this.message, this.title, this.config);
      }
      if (newValue == "success") {
        this.$toast.success(this.message, this.title, this.config);
      }
      if (newValue == "info") {
        this.$toast.info(this.message, this.title, this.config);
      }

      this.set_type(null);
    },
  },
  methods: {
    ...mapMutations("Toast", ["set_type"]),
  },
};
</script>
