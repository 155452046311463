import { biService } from "@/services";

export default {
  namespaced: true,
  state: {
    list: [],
    loadingList: false,
  },
  actions: {
    getList({ commit }) {
      commit("setLoadingList", true);
      biService.getList().then((resp) => {
        commit("setList", resp);
        commit("setLoadingList", false);
      });
    },
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    setLoadingList(state, bool) {
      state.loadingList = bool;
    },
  },
  getters: {
    getList(state) {
      return state.list;
    },
  },
};
