import base from "@/services/modules/base.service.js";

export const popService = {
  statusCount,
  getById,
  getListAutocomplete,
  getList,
  inactive,
  patch,
  post,
  generateQrcode,
  fetchPackages,
  fetchEvents,
  getHistory,
  sendCommand,
};

async function getList(options) {
  var response = null;
  let url = `/pops?page=${options.page}&per_page=${options.itemsPerPage}`;
  if (options.sortBy) {
    options.sortBy.map((s, i) => {
      url += `&order[]=${s} ${options.sortDesc[i] === true ? "desc" : "asc"}`;
    });
  }
  if (options.filter) {
    url += options.filter;
  }
  await base.fetch.get(url).then((resp) => {
    response = resp.data;
  });
  return response;
}

async function getListAutocomplete(search) {
  var response = null;
  if (search == null || search == "") {
    await this.getList({ page: 1, itemsPerPage: 10 }).then((resp) => {
      response = resp.data.data;
    });
  } else {
    await base.fetch
      .get(`/pops?q[name_cont]=${search ? search : ""}`)
      .then((resp) => {
        response = resp.data.data;
      });
  }
  return response;
}

async function getById(id) {
  var response = null;
  await base.fetch.get(`pops/${id}`).then((resp) => {
    response = resp.data.data;
  });
  return response;
}

async function inactive(id) {
  var response = null;
  await base.fetch.delete(`/pops/${id}`).then((resp) => {
    response = resp;
  });
  return response;
}

async function patch(id, params) {
  var response = null;
  await base.fetch.patch(`/pops/${id}`, params).then((resp) => {
    response = resp.data.data;
  });
  return response;
}

async function statusCount(query) {
  const response = await base.fetch.get(`/pops/status_count?${query}`);
  return response.data.data;
}

async function generateQrcode(id) {
  var response = null;
  await base.fetch.get(`/pops/${id}/qrcode_base64`).then((resp) => {
    response = resp;
  });
  return response;
}

async function post(params) {
  var response = null;
  await base.fetch.post("/pops/", params).then((resp) => {
    response = resp.data.data;
  });
  return response;
}

async function fetchPackages(popId, startAt, endAt, detailed = false) {
  const response = await base.fetch.get(
    `/pops/${popId}/packages?start_at=${startAt}&end_at=${endAt}${
      detailed ? "&detailed=true" : ""
    }`
  );
  return response.data;
}

async function fetchEvents(popId, subscriptionStartedAtRange) {
  const params = new URLSearchParams();
  params.append("q[pop_id_eq]", popId);

  if (subscriptionStartedAtRange[0]) {
    params.append(
      "q[subscription_started_at_gteq]",
      subscriptionStartedAtRange[0]
    );
  }

  if (subscriptionStartedAtRange[1]) {
    params.append(
      "q[subscription_started_at_lteq]",
      subscriptionStartedAtRange[1]
    );
  }

  const response = await base.fetch.get(`/events/?${params.toString()}`);
  return response.data;
}
async function getHistory(popId) {
  let url = `/pops/${popId}/versions?order[]=created_at desc`;
  const response = await base.fetch.get(url);
  return response.data;
}

async function sendCommand(popId, command) {
  var response = null;
  await base.fetch
    .post(`/pops/${popId}/request_mqtt_udev`, command)
    .then((resp) => {
      response = resp.data.data;
    });
  return response;
}
