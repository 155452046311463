import { accountService } from "@/services";
import i18n from "@/i18n";
import base from "@/services/modules/base.service.js";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    lowestSeverity: null,
    loading: false,
    loadingCRUD: false,
    showAnnotations: null,
    form: { id: null, name: null, email: null, phone_number: null },
  },
  actions: {
    getUserLogged({ commit }) {
      commit("setLoading", true);
      const id = localStorage.getItem("mf-userId");
      accountService
        .getUserLogged(id)
        .then((resp) => {
          commit("setUserLogged", resp);
          commit("setLoading", false);
        })
        .catch(() => {
          commit("setLoading", false);
        });
    },
    setForm({ commit }, form) {
      commit("setForm", form);
    },
    updateUserLogged({ commit, state, dispatch }) {
      commit("setLoadingCRUD", true);
      accountService.update(state.form.id, state.form).then((resp) => {
        commit("setUserLogged", resp);
        commit("setLoadingCRUD", false);
        dispatch(
          "Toast/toastSuccess",
          { message: "Perfil atualizado com sucesso." },
          { root: true }
        );
      });
    },
    updatePasswordUserLogged({ commit, state, dispatch }, form) {
      commit("setLoadingCRUD", true);
      accountService.update(state.form.id, form).then((resp) => {
        commit("setUserLogged", resp);
        commit("setLoadingCRUD", false);
        dispatch(
          "Toast/toastSuccess",
          { message: "Senha atualizado com sucesso." },
          { root: true }
        );
      });
    },
    updateAppearance({ commit, state, dispatch }) {
      commit("setLoadingCRUD", true);
      accountService.update(state.form.id, state.form).then((resp) => {
        commit("setUserLogged", resp);
        commit("setLoadingCRUD", false);
        dispatch(
          "Toast/toastSuccess",
          {
            message: "Preferências de aparência foram atualizado com sucesso.",
          },
          { root: true }
        );
      });
    },
    authenticating({ commit }) {
      commit("setLoading", true);
    },
    authenticationSuccessful({ commit }, user) {
      commit("setLoading", false);
      router.push("/");
      if (user) commit("setUserLogged", user);
    },
    authenticationFailed({ commit, dispatch }) {
      commit("setLoading", false);
      dispatch(
        "Toast/toastError",
        {
          message: i18n.t("login.msg.email_password_incorrect"),
          config: {
            backgroundColor: "#565c70",
            progressBarColor: "#ff3f43",
          },
        },
        { root: true }
      );
    },
    logout({ commit }) {
      base.logout();
      commit("setLoading", false);
    },
  },
  mutations: {
    setUserLogged(state, resp) {
      state.form = resp;
      state.showAnnotations = resp.config.appearance?.display_annotations;
      state.lowestSeverity =
        resp.config.appearance?.lowest_severity_of_interest;
    },
    setForm(state, resp) {
      state.form = resp;
    },
    setLoading(state, bool) {
      state.loading = bool;
    },
    setLoadingCRUD(state, bool) {
      state.loadingCRUD = bool;
    },
  },
  getters: {
    getName(state) {
      return state.form.name;
    },
    getEmail(state) {
      return state.form.email;
    },
    getRole(state) {
      return state.form.role;
    },
    getPhone(state) {
      return state.form.phone_number;
    },
    getForm(state) {
      return state.form;
    },
    getLoadingCRUD(state) {
      return state.loadingCRUD;
    },
    getLoading(state) {
      return state.loading;
    },
    getShowAnnotations(state) {
      return state.showAnnotations;
    },
    getLowestSeverityInterest(state) {
      return state.lowestSeverity;
    },
  },
};
