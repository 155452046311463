import { eventService } from "@/services";
import i18n from "@/i18n";
import EventHelper from "@/helpers/event.helper";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    events: [],
    menuCount: null,
    menuList: [],
    menuLoading: false,
    totalEvents: 0,
    trends: {
      total: {
        before: 0,
        after: 0,
        trend: 0,
      },
      warning: {
        before: 0,
        after: 0,
        trend: 0,
      },
      critical: {
        before: 0,
        after: 0,
        trend: 0,
      },
    },
    histogram: [],
    mostFrequentPops: [],
  },
  actions: {
    async save({ dispatch }, form) {
      await eventService
        .post(form)
        .then(() => {
          dispatch(
            "Toast/toastSuccess",
            {
              message: "Evento criado com sucesso",
            },
            { root: true }
          );
        })
        .catch((error) => Promise.reject(error));
    },
    async getListMenu({ commit, rootGetters, state }, page) {
      if (state.menuLoading != true) {
        commit("setMenuLoading", true);
        let array = [];
        ["Place", "Site", "Company", "Equipment"].forEach((element) => {
          array[element] = rootGetters[`Filter/get${element}`];
        });

        let query = `&q[active_eq]=true&q[severity_gteq]=${localStorage.getItem(
          "mf-lowestSeverity"
        )}`;
        for (let i in array) {
          if (array[i].id) {
            query += `&q[${i.toLowerCase()}_id_eq]=${array[i].id}`;
          }
        }

        const filter = {
          page: page,
          itemsPerPage: 15,
          sortBy: ["created_at"],
          sortDesc: [true],
          filter: query,
        };

        await eventService.getList(filter).then((resp) => {
          commit("setMenuCount", resp.meta.total_count);
          commit("setMenuList", resp.data);
          commit("setMenuLoading", false);
        });
      }
    },
    async fetchEvents({ commit, rootGetters }, page) {
      let array = [];
      ["Place", "Site", "Company", "Equipment"].forEach((element) => {
        array[element] = rootGetters[`Filter/get${element}`];
      });

      let query = `&q[severity_gteq]=${localStorage.getItem(
        "mf-lowestSeverity"
      )}`;
      for (let i in array) {
        if (array[i].id) {
          query += `&q[${i.toLowerCase()}_id_eq]=${array[i].id}`;
        }
      }

      query += rootGetters["Filter/getQueryFieldsFiltered"];
      const filter = {
        page: page,
        itemsPerPage: 15,
        sortBy: ["active", "severity", "created_at"],
        sortDesc: [true, true, true],
        filter: query,
      };

      await eventService.getList(filter).then((resp) => {
        commit("appendEvents", resp);
      });
    },
    async fetchEventsTrends({ commit, rootGetters }) {
      let array = [];
      ["Place", "Site", "Company", "Equipment"].forEach((element) => {
        array[element] = rootGetters[`Filter/get${element}`];
      });
      let query = `q[active_eq]=true&q[severity_gteq]=${localStorage.getItem(
        "mf-lowestSeverity"
      )}`;
      for (let i in array) {
        if (array[i].id) {
          query += `&q[${i.toLowerCase()}_id_eq]=${array[i].id}`;
        }
      }

      await eventService.trends(query).then((resp) => {
        commit("setEventsTrends", resp);
      });
    },
    async fetchHistogram({ commit, rootGetters }) {
      let array = [];
      ["Place", "Site", "Company", "Equipment"].forEach((element) => {
        array[element] = rootGetters[`Filter/get${element}`];
      });

      let query = "q[active_eq]=true";
      for (let i in array) {
        if (array[i].id) {
          query += `&q[${i.toLowerCase()}_id_eq]=${array[i].id}`;
        }
      }
      query += `&q[created_at_gt]=${moment().subtract(3, "months").calendar()}`;
      const response = await eventService.histogram(query);
      commit("setHistogram", response);
    },
    async fetchMostFrequentPops({ commit, rootGetters }) {
      let array = [];
      ["Place", "Site", "Company", "Equipment"].forEach((element) => {
        array[element] = rootGetters[`Filter/get${element}`];
      });

      let query = "q[active_eq]=true";
      for (let i in array) {
        if (array[i].id) {
          query += `&q[${i.toLowerCase()}_id_eq]=${array[i].id}`;
        }
      }
      const response = await eventService.mostFrequentPops(query);
      commit("setMostFrequentPops", response);
    },
    resetMenuList({ commit }) {
      commit("resetMenuList");
    },
    resetEvents({ commit }) {
      commit("resetEvents");
    },
  },
  mutations: {
    setEvents(state, events) {
      state.events = events;
    },
    setMenuList(state, resp) {
      state.menuList = state.menuList.concat(resp);
    },
    setMenuCount(state, resp) {
      state.menuCount = resp;
    },
    setMenuLoading(state, bool) {
      state.menuLoading = bool;
    },
    appendEvents(state, events) {
      state.events = state.events.concat(events.data);
      state.totalEvents = events.meta.total_count;
    },
    setEventsTrends(state, trends) {
      state.trends = trends;
    },
    setMostFrequentPops(state, resp) {
      state.mostFrequentPops = resp.pops_with_most_events.data
        ? resp.pops_with_most_events.data
        : [];
    },
    setHistogram(state, resp) {
      const histogram = resp.histogram;
      let series = [];
      for (let serie in histogram) {
        let entries = histogram[serie];
        let newEntries = [];
        for (let entry in entries) {
          newEntries.push([entries[entry].date, entries[entry].count]);
        }
        series.push({
          name: i18n.t(`event_type.${serie}`),
          color: EventHelper.colorForType(serie),
          data: newEntries,
        });
      }

      state.histogram = series;
    },
    resetMenuList(state) {
      state.menuList = [];
    },
    resetEvents(state) {
      state.events = [];
    },
  },
  getters: {
    getHistogram(state) {
      return state.histogram;
    },
    getMostFrequentPops(state) {
      return state.mostFrequentPops;
    },
    getTotalEvents(state) {
      return state.totalEvents;
    },
    getTrends(state) {
      return state.trends;
    },
    getEvents(state) {
      return state.events;
    },
    getMenuList(state) {
      return state.menuList;
    },
    getMenuCount(state) {
      return state.menuCount;
    },
    getMenuLoading(state) {
      return state.menuLoading;
    },
  },
};
