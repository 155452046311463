import base from "@/services/modules/base.service.js";

export const accountService = {
  getUserLogged,
  update,
};

async function getUserLogged(id) {
  var response = null;
  let url = "/users/" + id;
  await base.fetch.get(url).then((resp) => {
    response = resp.data.data;
    localStorage.setItem("mf-userRole", resp.data.data.role);
  });
  return response;
}

async function update(id, params) {
  var response = null;
  await base.fetch.patch(`/users/${id}`, params).then((resp) => {
    response = resp.data.data;
    localStorage.setItem(
      "mf-lowestSeverity",
      resp.data.data.config.appearance.lowest_severity_of_interest
    );
  });
  return response;
}
