import { siteService } from "@/services";
import i18n from "@/i18n";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    sites: [],
    headersDataTable: [
      {
        text: i18n.t("site.datatable.id"),
        value: "id",
        align: "left",
        width: 80,
      },
      {
        text: i18n.t("site.datatable.name"),
        value: "name",
      },
      {
        text: i18n.t("site.datatable.phone"),
        value: "phone",
      },
      {
        text: i18n.t("site.datatable.company"),
        value: "company.name",
      },
      {
        text: i18n.t("site.datatable.status"),
        value: "active",
        align: "center",
        width: 125,
      },
      {
        text: i18n.t("site.datatable.actions"),
        value: "actions",
        align: "center",
        width: 125,
        sortable: false,
      },
    ],
    optionsDataTable: {},
    menuCount: null,
    menuList: [],
    menuLoading: false,
    listCount: null,
    list: [],
    loadingList: false,
    form: {
      id: null,
      name: null,
      company_id: null,
      phone: null,
      address: {
        zipcode: null,
        state: null,
        city: null,
        neighborhood: null,
        street: null,
        complement: null,
      },
      lat: null,
      lng: null,
      active: true,
    },
  },
  actions: {
    async fetchSites({ commit, state, rootGetters }, detailed = false) {
      if (!state.loadingList) {
        commit("setLoadingList", true);
        const company = rootGetters["Filter/getCompany"];
        let query = "";
        if (company.id) {
          query += `&q[company_id_eq]=${company.id}`;
        }
        query += `&detailed=${detailed}`;
        query += rootGetters["Filter/getQueryFieldsFiltered"];

        let options = {
          filter: query,
          sortBy: ["active", "name"],
          sortDesc: [true, false],
        };

        siteService.getList(options).then((resp) => {
          commit("setList", resp);
          commit("setLoadingList", false);
        });
      }
    },
    getList({ commit, state, rootGetters }) {
      if (!state.loadingList && state.optionsDataTable.page !== undefined) {
        commit("setLoadingList", true);
        const company = rootGetters["Filter/getCompany"];
        let query = "";
        if (company.id) {
          query += `&q[company_id_eq]=${company.id}`;
        }

        query += rootGetters["Filter/getQueryFieldsFiltered"];

        state.optionsDataTable.filter = query;
        siteService.getList(state.optionsDataTable).then((resp) => {
          commit("setList", resp);
          commit("setLoadingList", false);
        });
      }
    },
    async getListMenu({ commit, rootGetters }, search) {
      commit("setMenuLoading", true);
      const company = rootGetters["Filter/getCompany"];
      let query = "&q[active_eq]=true";

      if (search) {
        query += `&q[name_cont]=${search}`;
      }

      if (company.id) {
        query += `&q[company_id_eq]=${company.id}`;
      }

      const filter = {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["name"],
        sortDesc: [false],
        filter: query,
      };
      await siteService.getList(filter).then((resp) => {
        if (!search) {
          commit("setMenuCount", resp.meta.total_count);
        }
        commit("setMenuList", resp.data);
        commit("setMenuLoading", false);
      });
    },
    getById({ commit }, id) {
      siteService.getById(id).then((resp) => {
        commit("setForm", resp);
      });
    },
    async save({ dispatch, commit, state }) {
      if (state.form.id) {
        let id = state.form.id;
        await siteService.patch(id, state.form).then((resp) => {
          commit("updateOnListIfPossible", resp);
          dispatch(
            "Toast/toastSuccess",
            {
              message: "Site atualizado com sucesso",
            },
            { root: true }
          );
        });
      } else {
        await siteService
          .post(state.form)
          .then((resp) => {
            commit("addOnList", resp);
            dispatch(
              "Toast/toastSuccess",
              {
                message: "Site criado com sucesso",
              },
              { root: true }
            );
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
    },
    resetForm({ commit }) {
      commit("resetForm");
    },
    setForm({ commit }, form) {
      commit("setForm", form);
    },
    toggleActive({ dispatch }, site) {
      if (site.active) {
        siteService.inactive(site.id).then(() => {
          dispatch("getList");
          dispatch("fetchSites");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.inactive_successfully", {
                model: i18n.tc("site.label", 1),
              }),
            },
            { root: true }
          );
        });
      } else {
        siteService.patch(site.id, { active: true }).then(() => {
          dispatch("getList");
          dispatch("fetchSites");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.active_successfully", {
                model: i18n.tc("site.label", 1),
              }),
            },
            { root: true }
          );
        });
      }
    },
    findByZipCode({ commit, dispatch }, zipcode) {
      // eslint-disable-next-line no-undef
      new google.maps.Geocoder().geocode(
        { address: zipcode },
        (results, status) => {
          if (status !== "OK") {
            dispatch(
              "Toast/toastError",
              {
                message: "Não foi encontrado nenhum endereço para este cep.",
              },
              { root: true }
            );
          } else {
            let addressJson = {};
            results[0].address_components.forEach((address) => {
              if (address.types.includes("administrative_area_level_1")) {
                addressJson.state = address.short_name;
              }
              if (address.types.includes("administrative_area_level_2")) {
                addressJson.city = address.short_name;
              }
              if (address.types.includes("sublocality_level_1")) {
                addressJson.neighborhood = address.short_name;
              }
              if (address.types.includes("route")) {
                addressJson.street = address.short_name;
              }
              if (address.types.includes("street_number")) {
                addressJson.street_number = address.short_name;
              }
            });
            addressJson.lat = results[0].geometry.location.lat();
            addressJson.lng = results[0].geometry.location.lng();
            commit("setAddress", addressJson);
          }
        }
      );
    },
    findByStreet({ commit, dispatch }, street) {
      // eslint-disable-next-line no-undef
      new google.maps.Geocoder().geocode(
        { address: street },
        (results, status) => {
          if (status !== "OK") {
            dispatch(
              "Toast/toastError",
              {
                message:
                  "Não foi encontrado nenhum endereço para este endereço.",
              },
              { root: true }
            );
          } else {
            let addressJson = {};
            results[0].address_components.forEach((address) => {
              if (address.types.includes("administrative_area_level_1")) {
                addressJson.state = address.short_name;
              }
              if (address.types.includes("administrative_area_level_2")) {
                addressJson.city = address.short_name;
              }
              if (address.types.includes("sublocality_level_1")) {
                addressJson.neighborhood = address.short_name;
              }
              if (address.types.includes("route")) {
                addressJson.street = address.short_name;
              }
              if (address.types.includes("street_number")) {
                addressJson.street_number = address.short_name;
              }
            });
            addressJson.lat = results[0].geometry.location.lat();
            addressJson.lng = results[0].geometry.location.lng();
            commit("setAddress", addressJson);
          }
        }
      );
    },
  },
  mutations: {
    setSites(state, sites) {
      state.sites = sites;
    },
    setList(state, resp) {
      state.list = resp.data;
      state.listCount = resp.meta?.total_count ?? 0;
    },
    setMenuList(state, resp) {
      state.menuList = resp;
    },
    setMenuCount(state, resp) {
      state.menuCount = resp;
    },
    setMenuLoading(state, bool) {
      state.menuLoading = bool;
    },
    setLoadingList(state, bool) {
      state.loadingList = bool;
    },
    updateOptionsDataTable(state, options) {
      state.optionsDataTable.page = options.page;
      state.optionsDataTable.itemsPerPage = options.itemsPerPage;
      state.optionsDataTable.sortBy = options.sortBy;
      state.optionsDataTable.sortDesc = options.sortDesc;
    },
    setForm(state, resp) {
      state.form = resp;
    },
    addOnList(state, resp) {
      state.list.unshift(resp);
    },
    resetForm(state) {
      state.form = {
        id: null,
        name: null,
        company_id: null,
        phone: null,
        address: {
          zipcode: null,
          state: null,
          city: null,
          neighborhood: null,
          street: null,
          complement: null,
        },
        lat: null,
        lng: null,
        active: true,
      };
    },
    updateOnListIfPossible(state, resp) {
      let index = _.findIndex(state.list, { id: resp.id });
      if (index !== -1) {
        state.list[index].active = resp.active;
        state.list[index].id = resp.id;
        state.list[index].name = resp.name;
        state.list[index].phone = resp.phone;
        state.list[index].company = resp.company;
      }
      index = _.findIndex(state.sites, { id: resp.id });
      if (index !== -1) {
        state.sites[index] = resp;
      }
    },
    setAddress(state, resp) {
      state.form.address.state = resp.state;
      state.form.address.city = resp.city;
      state.form.address.neighborhood = resp.neighborhood;
      state.form.address.street = `${resp.street ? resp.street : ""}${
        resp.street_number ? " - " + resp.street_number : ""
      }`;
      state.form.lat = resp.lat;
      state.form.lng = resp.lng;
    },
  },
  getters: {
    getHeaderDataTable(state) {
      return state.headersDataTable;
    },
    getSites(state) {
      return state.sites;
    },
    getLoadingList(state) {
      return state.loadingList;
    },
    getListCount(state) {
      return state.listCount;
    },
    getListLoaded(state) {
      return state.list;
    },
    getForm(state) {
      return state.form;
    },
    getMenuList(state) {
      return state.menuList;
    },
    getMenuCount(state) {
      return state.menuCount;
    },
    getMenuLoading(state) {
      return state.menuLoading;
    },
    getAddress(state) {
      let address = {};
      address.state = state.form.address.state;
      address.city = state.form.address.city;
      address.neighborhood = state.form.address.neighborhood;
      address.street = state.form.address.street;
      address.lat = state.form.lat;
      address.lng = state.form.lng;
      return address;
    },
  },
};
