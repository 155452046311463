import { popService } from "@/services";
import i18n from "@/i18n";
import _ from "lodash";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    status: [
      { name: "Inativo", value: "inactive" },
      { name: "Desconectado", value: "disconnected" },
      { name: "Passivo", value: "passive" },
      { name: "Em manutenção", value: "under_maintenance" },
      { name: "On", value: "on" },
    ],
    dataStatus: {
      events: {
        loading: false,
      },
      packages: {
        loading: false,
      },
    },
    types: [
      {
        name: "Vibração",
        value: "Pop::Vibration",
      },
      {
        name: "Nível",
        value: "Pop::Level",
      },
      {
        name: "Contador",
        value: "Pop::Counter",
      },
      {
        name: "Termômetro",
        value: "Pop::Thermometer",
      },
      {
        name: "Termômetro WS2 (Ayga)",
        value: "Pop::ThermometerWS2",
      },
      {
        name: "Qualidade de ambiente",
        value: "Pop::Environment",
      },
      {
        name: "Interruptor",
        value: "Pop::Switch",
      },
      {
        name: "Analógico",
        value: "Pop::Analog",
      },
      {
        name: "Sinalizador",
        value: "Pop::Signal",
      },
      {
        name: "Ar-condicionado",
        value: "Pop::AirConditioner",
      },
      {
        name: "Energia",
        value: "Pop::Energy",
      },
    ],
    headersDataTable: [
      {
        text: i18n.t("pop.datatable.id"),
        value: "id",
        align: "left",
        width: 80,
      },
      {
        text: i18n.t("pop.datatable.name"),
        value: "name",
      },
      {
        text: i18n.t("pop.datatable.operational_status"),
        value: "operational_status_text",
      },
      {
        text: i18n.t("pop.datatable.type"),
        value: "type_text",
      },
      {
        text: i18n.t("pop.datatable.company"),
        value: "company.name",
      },
      {
        text: i18n.t("pop.datatable.site"),
        value: "site.name",
      },
      {
        text: i18n.t("pop.datatable.place"),
        value: "place.name",
      },
      {
        text: i18n.t("pop.datatable.equipment"),
        value: "equipment.name",
      },
      {
        text: i18n.t("pop.datatable.modem"),
        value: "modem.mac",
      },
      {
        text: i18n.t("pop.datatable.sensor"),
        value: "sensor.serial",
      },
      {
        text: i18n.t("pop.datatable.created_at"),
        value: "created_at",
        create_slot: true,
      },
      {
        text: i18n.t("pop.datatable.actions"),
        value: "actions",
        align: "center",
        width: 125,
        sortable: false,
      },
    ],
    optionsDataTable: {},
    menuCount: null,
    menuList: [],
    menuLoading: false,
    listCount: null,
    list: [],
    history: [],
    loadingList: false,
    form: { id: null, active: true },
    popDetail: {},
    loadingStatusChart: false,
    statusChartData: {
      series: [],
      labels: [],
    },
    statusCount: {},
    packages: [],
    events: [],
    activeProfile: null,
    showMachineHealth: false,
  },
  actions: {
    getList({ commit, state, rootGetters }) {
      if (!state.loadingList && state.optionsDataTable.page !== undefined) {
        commit("setLoadingList", true);

        let array = [];
        ["Place", "Site", "Company", "Equipment"].forEach((element) => {
          array[element] = rootGetters[`Filter/get${element}`];
        });

        let query = "";
        for (let i in array) {
          if (array[i].id) {
            query += `&q[${i.toLowerCase()}_id_eq]=${array[i].id}`;
          }
        }

        query += rootGetters["Filter/getQueryFieldsFiltered"];
        state.optionsDataTable.filter = query;

        popService.getList(state.optionsDataTable).then((resp) => {
          commit("setList", resp);
          commit("setLoadingList", false);
        });
      }
    },

    async listPopsWithAppend({ commit, rootGetters }, page) {
      let array = [];
      ["Place", "Site", "Company", "Equipment"].forEach((element) => {
        array[element] = rootGetters[`Filter/get${element}`];
      });

      let query = "";
      for (let i in array) {
        if (array[i].id) {
          query += `&q[${i.toLowerCase()}_id_eq]=${array[i].id}`;
        }
      }

      query += rootGetters["Filter/getQueryFieldsFiltered"];

      const filter = {
        page: page,
        itemsPerPage: 15,
        sortBy: ["operational_status"],
        sortDesc: [true],
        filter: query,
      };

      await popService.getList(filter).then((resp) => {
        commit("appendPops", resp);
      });
    },
    async getHistory({ commit }, popId) {
      await popService.getHistory(popId).then((resp) => {
        commit("setHistory", resp.data);
      });
    },

    async getListMenu({ commit, rootGetters }) {
      commit("setMenuLoading", true);
      let array = [];
      ["Place", "Site", "Company", "Equipment"].forEach((element) => {
        array[element] = rootGetters[`Filter/get${element}`];
      });

      let query = "&q[active_eq]=true";
      for (let i in array) {
        if (array[i].id) {
          query += `&q[${i.toLowerCase()}_id_eq]=${array[i].id}`;
        }
      }

      const filter = {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["name"],
        sortDesc: [false],
        filter: query,
      };

      await popService.getList(filter).then((resp) => {
        commit("setMenuCount", resp.meta.total_count);
        commit("setMenuList", resp.data);
        commit("setMenuLoading", false);
      });
    },
    async getById({ commit }, { id, detail }) {
      await popService.getById(id).then((resp) => {
        detail ? commit("setPopDetail", resp) : commit("setForm", resp);
      });
    },
    async save({ dispatch, commit, state }) {
      if (state.form.id) {
        let id = state.form.id;
        await popService.patch(id, state.form).then((resp) => {
          commit("updateOnListIfPossible", resp);
          commit("updatePopDetail", resp);
          dispatch(
            "Toast/toastSuccess",
            {
              message: "Ponto de operação atualizado com sucesso",
            },
            { root: true }
          );
        });
      } else {
        await popService
          .post(state.form)
          .then((resp) => {
            commit("addOnList", resp);
            dispatch(
              "Toast/toastSuccess",
              {
                message: "Ponto de operação criado com sucesso",
              },
              { root: true }
            );
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
    },
    resetForm({ commit }) {
      commit("resetForm");
    },
    setForm({ commit }, form) {
      commit("setForm", form);
    },
    toggleActive({ dispatch }, pop) {
      if (pop.status !== "inactive") {
        popService.inactive(pop.id).then(() => {
          dispatch("getList");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.inactive_successfully", {
                model: i18n.tc("pop.label", 1),
              }),
            },
            { root: true }
          );
        });
      } else {
        popService.patch(pop.id, { status: "on" }).then(() => {
          dispatch("getList");
          dispatch(
            "Toast/toastSuccess",
            {
              message: i18n.t("default.msg.active_successfully", {
                model: i18n.tc("pop.label", 1),
              }),
            },
            { root: true }
          );
        });
      }
    },
    async fetchStatusCount({ commit, rootGetters }) {
      commit("setLoadingStatusChart", true);
      let array = [];
      ["Place", "Site", "Company", "Equipment"].forEach((element) => {
        array[element] = rootGetters[`Filter/get${element}`];
      });

      let query = "";
      for (let i in array) {
        if (array[i].id) {
          query += `&q[${i.toLowerCase()}_id_eq]=${array[i].id}`;
        }
      }
      query += rootGetters["Filter/getQueryFieldsFiltered"];

      await popService.statusCount(query).then((resp) => {
        commit("setStatusChartData", resp);
        commit("setLoadingStatusChart", false);
      });
    },
    resetListPops({ commit }) {
      commit("resetListPops");
    },
    async fetchPackages({ commit }, { popId, startAt, endAt }) {
      commit("setLoadingDataStatus", { property: "packages" });
      const response = await popService.fetchPackages(popId, startAt, endAt);
      commit("setPackages", response.data);
      commit("setLoadingDataStatus", { property: "packages", loading: false });
    },
    async fetchEvents({ commit }, { popId, subscriptionStartedAtRange }) {
      commit("setLoadingDataStatus", { property: "events" });
      const response = await popService.fetchEvents(
        popId,
        subscriptionStartedAtRange
      );
      commit("setEvents", response.data);
      commit("setLoadingDataStatus", { property: "events", loading: false });
    },
    async saveProfileAirConditioner({ dispatch, state, commit }, profile) {
      if (Number.isInteger(profile.index)) {
        commit("updateProfile", profile);
      } else {
        commit("setNewProfile", profile);
      }
      let form = { config: state.popDetail.config };
      let id = state.popDetail.id;
      await popService.patch(id, form).then((resp) => {
        commit("setPopDetail", resp);
        dispatch(
          "Toast/toastSuccess",
          {
            message: "Perfil criado com sucesso",
          },
          { root: true }
        );
      });
    },
    async removeProfileAirConditioner({ dispatch, state, commit }, index) {
      commit("removeProfile", index);
      let form = { config: state.popDetail.config };
      let id = state.popDetail.id;
      await popService.patch(id, form).then((resp) => {
        commit("setPopDetail", resp);
        dispatch(
          "Toast/toastSuccess",
          {
            message: "Perfil removido com sucesso",
          },
          { root: true }
        );
      });
    },
    verifyActiveProfileAirConditioner({ state, commit }) {
      let numberWeekDay = moment().isoWeekday();
      let hoursFromNow = moment().format("HH:mm");
      let activeProfile = state.popDetail?.config?.profiles
        ?.map((e) => {
          if (e.days.includes(numberWeekDay)) {
            let periodSplitted = e.period.split(" ").filter((i) => i != "até");
            let now_time = moment(hoursFromNow, "HH:mm");
            let start_time = moment(periodSplitted[0], "HH:mm");
            let end_time = moment(periodSplitted[1], "HH:mm");
            if (
              moment.duration(start_time.diff(now_time)).asHours() <= 0 &&
              moment.duration(end_time.diff(now_time)).asHours() >= 0
            ) {
              return e;
            }
          }
        })
        .filter(Boolean);

      if (activeProfile) commit("setActiveProfile", activeProfile.shift());
    },
    async saveHistoryHidro({ dispatch, state, commit }, history) {
      if (Number.isInteger(history.index)) {
        commit("updateHistoryHidro", history);
      } else {
        commit("setNewHistoryHidro", history);
      }
      let form = { config: state.popDetail.config };
      let id = state.popDetail.id;
      await popService.patch(id, form).then((resp) => {
        commit("setPopDetail", resp);
        dispatch(
          "Toast/toastSuccess",
          {
            message: "Histórico criado com sucesso",
          },
          { root: true }
        );
      });
    },
    async removeHistoryHidro({ dispatch, state, commit }, index) {
      commit("removeHistoryHidro", index);
      let form = { config: state.popDetail.config };
      let id = state.popDetail.id;
      await popService.patch(id, form).then((resp) => {
        commit("setPopDetail", resp);
        dispatch(
          "Toast/toastSuccess",
          {
            message: "Histórico removido com sucesso",
          },
          { root: true }
        );
      });
    },
    async sendCommand({ dispatch, state }, command) {
      let form = { command: command };
      let id = state.popDetail.id;
      await popService.sendCommand(id, form).then(() => {
        dispatch(
          "Toast/toastSuccess",
          {
            message: "Comando enviado",
          },
          { root: true }
        );
      });
    },
  },
  mutations: {
    setActiveProfile(state, profile) {
      state.activeProfile = profile;
    },
    setNewProfile(state, profile) {
      profile.days = profile.days.sort();
      if (!state.popDetail.config.profiles) {
        state.popDetail.config["profiles"] = [];
      }
      state.popDetail.config.profiles.push(profile);
    },
    updateProfile(state, profile) {
      profile.days = profile.days.sort();
      state.popDetail.config.profiles[profile.index] = profile;
    },
    removeProfile(state, index) {
      state.popDetail.config.profiles.splice(index, 1);
    },
    setNewHistoryHidro(state, history) {
      if (!state.popDetail.config.visual_count) {
        state.popDetail.config["visual_count"] = [];
      }
      state.popDetail.config.visual_count.push(history);
    },
    updateHistoryHidro(state, history) {
      state.popDetail.config.visual_count[history.index] = history;
    },
    removeHistoryHidro(state, index) {
      state.popDetail.config.visual_count.splice(index, 1);
    },
    setList(state, resp) {
      state.list = resp.data;
      state.listCount = resp.meta.total_count;
    },
    setMenuList(state, resp) {
      state.menuList = resp;
    },
    setMenuCount(state, resp) {
      state.menuCount = resp;
    },
    setMenuLoading(state, bool) {
      state.menuLoading = bool;
    },
    setLoadingList(state, bool) {
      state.loadingList = bool;
    },
    updateOptionsDataTable(state, options) {
      state.optionsDataTable.page = options.page;
      state.optionsDataTable.itemsPerPage = options.itemsPerPage;
      state.optionsDataTable.sortBy = options.sortBy;
      state.optionsDataTable.sortDesc = options.sortDesc;
    },
    setForm(state, resp) {
      state.form = resp;
    },
    setPopDetail(state, resp) {
      state.popDetail = resp;
    },
    addOnList(state, resp) {
      state.list.unshift(resp);
    },
    resetForm(state) {
      state.form = {
        id: null,
        type: null,
        active: true,
        config: {},
        sensor_id: null,
        sensor: {},
        equipment_id: null,
      };
      state.showMachineHealth = false;
    },
    updateOnListIfPossible(state, resp) {
      let index = _.findIndex(state.list, { id: resp.id });
      if (index !== -1) {
        state.list[index].id = resp.id;
        state.list[index].name = resp.name;
        state.list[index].type_text = resp.type_text;
        state.list[index].company = resp.company;
        state.list[index].site = resp.site;
        state.list[index].place = resp.place;
        state.list[index].modem = resp.modem;
        state.list[index].sensor = resp.sensor;
      }
    },
    updatePopDetail(state, resp) {
      if (state.popDetail.id == resp.id) {
        state.popDetail = resp;
      }
    },
    setLoadingStatusChart(state, bool) {
      state.loadingStatusChart = bool;
    },
    setStatusChartData(state, statusChartData) {
      const series = [];
      const labels = [];
      for (let entry in statusChartData) {
        series.push({ [`${entry}`]: statusChartData[entry] });
        labels.push(i18n.t(`pop.status.${entry}`));
      }
      state.statusChartData = {
        series: series,
        labels: labels,
        ...statusChartData,
      };
    },
    setStatusCount(state, statusCount) {
      History;
      state.statusCount = statusCount;
    },
    appendPops(state, pops) {
      state.list = state.list.concat(pops.data);
      state.listCount = pops.meta.total_count;
    },
    setHistory(state, history) {
      state.history = history;
    },
    resetListPops(state) {
      state.list = [];
    },
    setPackages(state, packages) {
      state.packages = packages;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setLoadingDataStatus(state, { property, loading = true }) {
      state.dataStatus[property].loading = loading;
    },
    setShowMachineHealth(state, bool) {
      state.showMachineHealth = bool;
    },
  },
  getters: {
    getHeaderDataTable(state) {
      return state.headersDataTable;
    },
    getPackages(state) {
      return state.packages;
    },
    getLoadingList(state) {
      return state.loadingList;
    },
    getListCount(state) {
      return state.listCount;
    },
    getListLoaded(state) {
      return state.list;
    },
    getStatusChartData(state) {
      return state.statusChartData;
    },
    getPopTypes(state) {
      return state.types;
    },
    getPopStatus(state) {
      return state.status;
    },
    getForm(state) {
      return state.form;
    },
    getPopDetail(state) {
      return state.popDetail;
    },
    getEvents(state) {
      return state.events;
    },
    getLoadingStatusChart(state) {
      return state.loadingStatusChart;
    },
    getMenuList(state) {
      return state.menuList;
    },
    getMenuCount(state) {
      return state.menuCount;
    },
    getMenuLoading(state) {
      return state.menuLoading;
    },
    getHistoryHidro(state) {
      return state.popDetail?.config?.visual_count;
    },
    getProfilesAirConditioner(state) {
      return state.popDetail?.config?.profiles;
    },
    getActiveProfile(state) {
      return state.activeProfile;
    },
    getShowMachineHealth(state) {
      return state.showMachineHealth;
    },
  },
};
