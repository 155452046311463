import i18n from "@/i18n";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    dialog: false,
    title: "",
    store: null,
    item: null,
    formComponent: null,
    nestedItems: {},
  },
  actions: {
    openDialog({ commit }, payload) {
      commit("setStore", payload.store);
      if (payload.item) {
        commit(
          "setTitle",
          i18n.t(`register_dialog.edit.${payload.store.toLowerCase()}`)
        );
        commit("setItem", payload.item);
        if (payload.nested) {
          let current = {},
            key = `${_.snakeCase(payload.store)}_id`;
          current[key] = payload.item.id;
          payload.nested = { ...payload.nested, ...current };
          commit("setNested", payload.nested);
        }
      } else {
        commit(
          "setTitle",
          i18n.t(`register_dialog.new.${payload.store.toLowerCase()}`)
        );
        commit("setItem", {});
      }
      commit("setFormComponent", () =>
        import(`@/components/forms/${payload.store}Form.vue`)
      );
      commit("setDialog", true);
    },
    closeDialog({ commit }) {
      commit("setTitle", "");
      commit("setStore", null);
      commit("setDialog", false);
      commit("setItem", {});
      commit("resetNestedItems");
    },
    updateDialog({ commit }, val) {
      if (!val) {
        commit("setTitle", "");
        commit("setStore", null);
        commit("setDialog", false);
        commit("setItem", {});
        commit("resetNestedItems");
      }
      commit("setDialog", val);
    },
    async changeForm({ commit, state }, nStore) {
      let item = new Object();
      if (!_.isEmpty(state.nestedItems)) {
        switch (nStore) {
          case "CompanyGroup":
            if (state.nestedItems.company_group_id) {
              item.id = state.nestedItems.company_group_id;
            }
            break;
          case "Company":
            if (state.nestedItems.company_id) {
              item.id = state.nestedItems.company_id;
            }
            break;
          case "Site":
            if (state.nestedItems.site_id) {
              item.id = state.nestedItems.site_id;
            }
            break;
          case "Place":
            if (state.nestedItems.place_id) {
              item.id = state.nestedItems.place_id;
            }
            break;
          case "Equipment":
            if (state.nestedItems.equipment_id) {
              item.id = state.nestedItems.equipment_id;
            }
            break;
          case "Modem":
            if (state.nestedItems.modem_id) {
              item.id = state.nestedItems.modem_id;
            }
            break;
          case "Sensor":
            if (state.nestedItems.sensor_id) {
              item.id = state.nestedItems.sensor_id;
            }
            break;
          case "Pop":
            if (state.nestedItems.pop_id) {
              item.id = state.nestedItems.pop_id;
            }
            break;
        }
      }

      commit("setTitle", i18n.t(`register_dialog.new.${nStore.toLowerCase()}`));
      commit("setStore", nStore);
      commit("setItem", item);
    },
  },
  mutations: {
    setDialog(state, bool) {
      state.dialog = bool;
    },
    setTitle(state, val) {
      state.title = val;
    },
    setStore(state, store) {
      state.store = store;
    },
    setItem(state, item) {
      state.item = item;
    },
    setFormComponent(state, component) {
      state.formComponent = component;
    },
    setNested(state, nested) {
      state.nestedItems = nested;
    },
    resetNestedItems(state) {
      state.nestedItems = {};
    },
  },
  getters: {
    getDialog(state) {
      return state.dialog;
    },
    getTitle(state) {
      return state.title;
    },
    getStore(state) {
      return state.store;
    },
    getItem(state) {
      return state.item;
    },
    getFormComponent(state) {
      return state.formComponent;
    },
  },
};
